.dashboard-card {
  display: flex;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);

  .card-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .card-info {
    padding-left: 16px;
    border-left: 1px solid #EBECF0;

    .number-info {
      font-weight: bold;
      font-size: 30px;
    }

    .label-info {
      font-size: 16px;
      color: #091E42;
    }
  }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;