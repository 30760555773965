.events-container {
  p {
    margin: 0;
  }
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);

  .table-container {
    max-height: 268px;
    overflow-y: "auto";
  }
  .headerCell {
    font-size: 12px;
    color: "#7A869A";
  }
  .primaryCell {
    font-weight: 600;
    font-size: 14;
    color: "#091E42";
  }
  .normalCell {
    font-size: 14;
    color: #42526E;
  }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;