.header-card-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .card-label {
    font-weight: 600;
    font-size: 18px;
    color: #091E42;
  }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;