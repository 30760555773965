.upcomming-matches-container {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);

  p {
    margin: 0;
  }
  .header-card-dashboard {
    margin-bottom: 0;
  }
  .start-time {
    font-weight: bold;
  }

  .matches-logos {
    display: flex;
    align-items: center;

    .vs-label {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0;
      background: #DFE1E6;
      border-radius: 2px;
      padding: 5px 7px;
      font-weight: bold;
      font-size: 13px;
      color: #153993;
    }
  }

  .bet-rate {
    font-weight: bold;
    font-size: 14px;
    color: #153993;
  }

}

@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;