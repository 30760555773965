.channels-container {
  p {
    margin: 0;
  }
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  @apply bg-white;

  .table-container {
    max-height: 268px;
    overflow-y: "auto";

    .channel-name {
      font-size: 14px;
      color: #091E42;
    }

    .Draft {
      background: #FFAB00;
      border-radius: 100px;
      padding: 4px 12px;
      color: white;
      display: inline-block;
      font-size: 12px;
    }

    .Published {
      background: #0098D9;
      border-radius: 100px;
      padding: 4px 12px;
      color: white;
      display: inline-block;
      font-size: 12px;
    }

    .Active {
      border: 1px solid #36B37E;
      border-radius: 100px;
      padding: 4px 12px;
      display: inline-block;
      font-size: 12px;
    }

    .Off {
      border: 1px solid #C1C7D0;
      border-radius: 100px;
      padding: 4px 12px;
      display: inline-block;
      font-size: 12px;
      color: #C1C7D0;
    }
  }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;