.dashboard-page-index {

    .dashboard-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
        margin-top: 24px;

        &.sports-container {
            grid-template-columns: 1fr 2fr;
        }
    }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;