$color-base: #1DA57A;

.box-bg {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	background-color: #fff;
}
.overlay_show {
	animation: overlayShow 0.3s forwards;
}
.overlay_hide {
	animation: overlayHide 0.3s forwards;
}

.sk-cube-grid {
	top: 30%;
	text-align: center;
	left: 50%;
	transform: translateX(-50%);
	color: $color-base;
	font-size: 18px;
}
.lds-hourglass {
	display: inline-block;
	position: relative;
	width: 65px;
	height: 65px;
}
.lds-hourglass:after {
	content: " ";
	display: block;
	border-radius: 50%;
	width: 0;
	height: 0;
	margin: 8px;
	box-sizing: border-box;
	border: 24px solid $color-base;
	border-color: $color-base transparent $color-base transparent;
	animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
}

@keyframes overlayHide {
	from {
		z-index: 999;
		opacity: 0.8;
	}
	to {
		z-index: -1;
		opacity: 0;
	}
}

@keyframes overlayShow {
	from {
		z-index: -1;
		opacity: 0;
	}
	to {
		z-index: 999;
		opacity: 1;
	}
}
