.overview-container {
  .title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    
    .page-title {
        font-weight: bold;
        font-size: 24px;
        color: #091E42;
    }
  }
  
  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
  }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;