.popular-sports-container {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);

  .header-card-dashboard {
    align-items: unset;
  }

  .filter-dashboard {
      flex-direction: column;
      align-items: unset;

      .filter-label {
        padding-left: 8px;
      }
  }
}
@primary-color: #4b6bfb;@font-family: Roboto, system-ui, sans-serif;